<template>
	<div class="questionbg">
		<div style="height: 140px;"></div>
		
		<div style="display: flex;padding: 0 20px;width: 100%;line-height:40px;">
			<div style="padding:0 10px;font-size: 12px;"><i class="icon-fanhui icon-size"></i> 测评进度 </div>
			<div style="flex:1;display: flex;align-items: center;justify-content: center;">
				<div style="width: 100%;">
					<van-progress :percentage="percentage" :show-pivot="false" stroke-width="8" track-color="#fff" color="#2D6FDF" pivot-color="#2D6FDF" text-color="#fff" :pivot-text="pivotText" />
				</div>
			</div>
			<div style="font-size: 12px;width: 70px;margin-left: 10px;margin-right: 10px;"> <span style="font-size: 16px;color:#2D6FDF;">{{ activeTab }}</span> / {{ subject.length }}</div>
		</div>
		
		<div class="question-list" style="flex: 1;">
			<div v-for="(item,index) in subject" :key="item.id" v-show="activeTab-1 === index">
				<div class="question-title">{{ item.questionDesc }}</div>
				<div>
					<van-radio-group v-model="item.selectOptionId" @change="handleRadioChange">
						<van-radio :name="answer.code" v-for="(answer, index) in item.optionList" :key="index" checked-color="transparent" :class="item.selectOptionId==answer.code ? 'zk-radio checked' :'zk-radio'">
							{{ answer.optionDesc }}
						</van-radio>
					</van-radio-group>
				</div>				
			</div>
		</div>
		
		<div class="footer-btn">
			<div class="" @click="preStem" :disabled="activeTab==1">
				<i class="icon-fanhui icon-size"></i>上一题
			</div>
			<template>
				<div class="" @click="subQuestion" v-if="activeTab == subject.length ">
					提交
				</div>
				<div class=" " @click="nextStem()" v-else>
					下一题<i class="icon-gengdu-copy icon-size"></i>
				</div>
			</template>
		</div>
		
		<!-- <div class="questionlist" @click="showList">
			<van-icon name="bars" />
		</div> -->
		
		<van-popup v-model="popShow" position="right" :style="{ height: '100%', width: '70%' }">
			<div class="we-bg-color-grey disinline" :class="{ active: item.selectOptionId !== null }" v-for="(item, index) in subject" :key="index" @click="handleChoice(item, index)">
				{{index+1 }}
			</div>
		</van-popup>
	</div>
</template>

<script>
	import { Progress, RadioGroup, Radio, Popup, Icon, Dialog, } from "vant";
	import { questionitem, saveitem } from "@api/question";
	export default {
		name: "questiondetail",
		components: {
			[Progress.name]: Progress,
			[Radio.name]: Radio,
			[RadioGroup.name]: RadioGroup,
			[Popup.name]: Popup,
			[Icon.name]: Icon,
			[Dialog.name]: Dialog,
		},
		data() {
			//  this.lastFetchId = 0;
			// this.fetchUser = debounce(this.fetchUser, 800);
			return {
				title: "平和质",
				answer: "",
				predisabel: true,
				subject: [
					// {
					// 	id:1,
					// 	questionDesc:"平和质",
					// 	optionList:[
					// 		{optionDesc:1,code:"1"},
					// 		{optionDesc:2,code:"2"},
					// 		{optionDesc:3,code:"3"},
					// 	]
					// },
					// {
					// 	id:3,
					// 	questionDesc:"11122",
					// 	optionList:[
					// 		{optionDesc:1,code:"1"},
					// 		{optionDesc:2,code:"2"},
					// 		{optionDesc:3,code:"3"},
					// 	]
					// },
				], //问卷题目
				activeTab: 1,
				isEnd: false,
				percentage: 0,
				selectOptionId: [],
				showQuestion: true,
				decide: "", //体质鉴别
				id: "", //报告id
				tropesis: "", //倾向
				initill: 0,
				doctorId: "", //医生id
				activeIcon: "https://img.yzcdn.cn/vant/user-active.png",
				inactiveIcon: "https://img.yzcdn.cn/vant/user-inactive.png",
				popShow: false,
				total: null,
				pivotText: "",
				questionListArray: [],
			};
		},
		computed: {},
		mounted() {
			// const doctorId = window.sessionStorage.getItem("doctorId");
			const code = this.$route.params.code;
			this.code = code;
			if(code){
				this.ajax_getQuestion(code);
			}
			
		},
		methods: {
			// 上一题
			preStem() {
				this.isEnd = false;
				if (this.activeTab == 1) {
					this.predisabel = true;
				} else {
					this.activeTab--;
				}
				this.showPivot();
			},
			// 下一题
			nextStem() {
				let answer = this.subject[this.activeTab - 1].selectOptionId;
				if (answer == null) {
					this.$toast("请选择");
				} else {
					if (this.activeTab == this.total) {
						return false;
					} else {
						this.activeTab++;
					}
					this.showPivot();
				}
			},
			//点击答案直接下一题
			handleRadioChange() {
				if (this.activeTab == this.subject.length * 1) {
					return false;
				} else {
					setTimeout(() => {
						this.nextStem();
					}, 200);
				}
			},
			//提交问卷
			subQuestion() {
				this.$toast({
					message: "正在提交...",
					forbidClick: true,
				});

				let ifMiss = this.subject.filter((item) => {
					return item.selectOptionId == null;
				});
				if (ifMiss.length == 0) {
					let questionSubmitDtoList = [];

					questionSubmitDtoList = this.subject.map((s) => {
						let {
							option,
							questionId
						} = s;
						option = s.selectOptionId;
						questionId = s.id;
						return {
							option,
							questionId
						};
					});
					let pramas = {
						code: this.code,
						questionSubmitDtoList: questionSubmitDtoList,
					};
					saveitem(pramas).then((res) => {
						if (res.respCode == 200) {
							Dialog.alert({
								title: "提示",
								message: "恭喜完成体质辨识信息采集",
							}).then(() => {
								// on close
								this.$router.push({
									name: "analysisdetail",
									params: {
										id: res.result
									},
								});
							});
						} else {
							this.$toast(res.respMsg);
						}
					});
				} else {
					this.$toast({
						message: "未做答完成，请完善答案",
						forbidClick: true,
					});
				}
			},

			// 获取题目列表
			ajax_getQuestion(code) {
				questionitem(code).then((res) => {
					if (res.respCode == 200) {
						this.subject = res.result;
						this.total = res.result.length;
						// this.questionListArray = questionListArray;
						this.pivotText = "1/" + this.total;
						// this.subject = res.body;
					} else {
						this.$toast(res.respMsg);
					}
				});
			},
			//显示进度条
			showPivot() {
				if (this.activeTab == 1) {
					this.percentage = 0;
				} else if (this.activeTab >= this.subject.length) {
					this.percentage = 100;
				} else {
					this.percentage =
						((this.activeTab * 1) / this.subject.length) * 1 * 100;
				}
				if (this.activeTab > this.subject.length) {
					this.pivotText = `${this.total}/${this.total}`;
					return false;
				} else {
					this.pivotText = `${this.activeTab}/${this.total}`;
				}
			},
			//显示题目列表
			showList() {
				this.popShow = true;
			},
			//题目列表
			handleChoice(subject, index) {
				if (subject.selectOptionId == null) {
					this.$toast("尚未作答");
				} else {
					this.activeTab = index + 1;
					// this.activeStem = index;
					this.popShow = false;
					//  this.pivotText = `${this.activeTab}/${this.total}`;
					//  this.percentage = ((this.activeTab * 1) / this.subject.length) * 1 * 100
					this.showPivot();
				}
			},
		},
	};
</script>
<style lang="less" scope>
	
	.questionbg{
		background:url("/images/bg_wenjuan2.png");background-size: 100% 100%;position:fixed;inset:0;display: flex;flex-direction: column;
	}
	
	.footer-btn{height:40px;display:flex;font-size: 12px;}
	.footer-btn > div{flex: 1;text-align:center;line-height:30px;}
	.question-title{
		font-weight:bold;font-size: 20px;text-align:center;margin-bottom: 6px;padding: 30px 0;
	}
	
	.question-list{
		padding: 0 30px;
	}
	
	
	.zk-radio{
		// background: url(/images/btn_bj.png);
		background: #fff;
		border: solid 1px #e5e5e5;
		border-radius: 50px;
		text-align:center;
		margin-bottom: 10px;
		position: relative;
		height: 45px;
		line-height: 45px;
	}
	.zk-radio > div{display: inline-block;}
	.zk-radio.checked{
		background: url(/images/btn_bj.png);
		background-size: 100% 100%;
		border: none;
		.van-radio__label{			
			color: #fff;
		}
		.van-radio__label:before{
			content:'\e728';
			font: normal normal normal 0.37333rem / 1 var(--van-icon-font-family, 'vant-icon');
			font-size: 14px;
			margin-right: 5px;
		}
	}
	.zk-radio .van-radio__icon .van-icon{border: none;}
	.zk-radio .van-radio__label{
		text-align:center;position: absolute;left: 0;right: 0;margin: 0;
	}
	.zk-radio  .van-radio__icon{
		display: none;
	}
	
	.icon-size{font-size: 12px;}
	
	

	.questionlist {
		position: absolute;
		bottom: 100px;
		right: 20px;
	}

	.questionlist {
		position: fixed;
		right: 20px;
		bottom: 100px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		// background-color: #009c30;
		// color: #fff;
		font-size: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 5px 5px 0px rgba(6, 0, 1, 0.2);
		// border-radius: 50%;
	}

	.disinline {
		display: inline-block;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		margin: 10px;
		border-radius: 50%;
	}

	.active {
		background-color: #2D6FDF;
		color: #fff;
	}
</style>
