import axios from "axios";
import api from './api';
//获取问卷
export const questionitem = code => {
    return api.get(`/patientconstitution/questionnaire/${code}`)
};


//提交问卷
export const saveitem = (params) => {
    return api.post(`/patientconstitution/submit`,params)
};
export const additem = (params) => {
    return api.post(`/product/save`,params)
};
export const deleteitem = id => {
    return api.post(`/product/delete`,{id})
}
//获得全部产品分类
export const getAllProductType =  (params) => {
    return api.get(`/product/type/getAllProductType`,params)
}